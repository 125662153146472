<template>
  <div>
    <b-row class="mb-3">
      <b-col sm="12">
        <div class="mt-1">
          <div>
            <h6 class="mb-3 tag">{{ $t('dealer.actions') }}</h6>
            <h4 v-if="appDetail.pay_status === 0 && appDetail.status === 0" class="text-center text-danger">{{$t('dealer.payment_list_status')}} </h4>
            <h4 v-else-if="appDetail.pay_status === 0 && appDetail.status === 4" class="text-center text-danger">
              <span v-if="agreementDeadline">
                  {{ currentLocale === 'bn' ? ' চুক্তি পেমেন্টের সময়সীমা শেষ।' : 'The agreement payment deadline is over.'}}
              </span>
              <span v-else>
                   <template v-if="currentLocale === 'bn'"> চুক্তির জন্য {{agreementDeadlineDate|dateFormat}} তারিখের মধ্যে পেমেন্ট প্রক্রিয়া সম্পূর্ণ করুন</template>
                   <template v-else>Complete payment process within {{agreementDeadlineDate|dateFormat}} for the Agreement</template>
              </span>
            </h4>
             <span v-else>
                  <h4 class="text-center" style="color:#ee5253;" v-if="stepList(appDetail.status).value < appDetail.status || !appDetail.agreement_no">{{ currentLocale === 'bn' ? stepList(appDetail.status).text_bn : stepList(appDetail.status).text_en}}</h4>
                  <h4 class="text-center text-success" v-if="stepList(appDetail.status).value === 4 && appDetail.agreement_no">{{ currentLocale === 'bn' ? 'স্বাগতম আপনি এখন টিসিবির অনুমোদিত ডিলার' : 'Welcome you are now authorized dealer of TCB'}}</h4>
            </span>
          </div>
          <b-button :disabled="agreementDeadline" v-if="appDetail.pay_status === 0" @click="checkLimitApp(appDetail)" class="btn-sm" variant="warning">{{ $t('dealer.pay') }}</b-button>&nbsp;
          <b-button v-if="appDetail.pay_status > 0" v-b-modal.receipt class="btn-sm" variant="primary">{{ $t('dealer.receipt') }}</b-button>&nbsp;
          <b-button v-if="appDetail.review_status === 0 && appDetail.agreement_no" v-b-modal.modal-form class="btn-sm" variant="success">{{ $t('globalTrans.review') }}</b-button>&nbsp;
          <b-button v-if="isRenewButton" @click="enableRenew()" class="btn-sm" variant="info">{{ $t('dealer.renew') }} </b-button>&nbsp;
          <b-button v-if="isExpiredStatus" class="btn-sm" variant="info">{{ $t('dealer.expire_status')}} </b-button>
        </div>
      </b-col>
    </b-row>
    <div>
    <b-modal id="payment" size="lg" :title="$t('dealer.payment')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger" ref="payment-modal">
      <Payment :application="appDetail" />
    </b-modal>
    <b-modal id="modal-form" size="lg" :title="$t('globalTrans.review')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Review :application="appDetail" />
    </b-modal>
    <b-modal id="receipt" size="lg" :title="$t('dealer.receipt')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Receipt :application="appDetail" />
    </b-modal>
  </div>
  </div>
</template>
<script>
import Payment from './Payment.vue'
import Review from './Review.vue'
import Receipt from './Receipt.vue'
import moment from 'moment'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { getWardUnionAppLimit, noticeRecuirmentNoticeById } from '../../api/routes'
import { dateFormat } from '../../../../../../utils/fliter'

export default {
  props: ['appDetail'],
  name: 'Action',
  components: {
    Payment,
    Review,
    Receipt
  },
  data () {
    return {
      recruitmentDelears: [],
      loading: false,
      appLimit: false,
      agreementDeadline: false,
      agreementDeadlineDate: '',
      isRenewButton: false,
      isExpiredStatus: false
    }
  },
  created () {
    if (this.appDetail.pay_status === 0 && this.appDetail.approval_status === 1 && this.appDetail.status === 4) {
       const lastUpdateDate = this.appDetail.updated_at
       const initialDate = new Date(lastUpdateDate)
       const nextMonthDate = new Date(initialDate)
        nextMonthDate.setMonth(initialDate.getMonth() + 1)
       this.agreementDeadlineDate = nextMonthDate.toISOString()
       if (nextMonthDate >= new Date()) {
        this.agreementDeadline = false
       } else {
        this.agreementDeadline = true
       }
    }
    this.checkRenewStatus()
  },
  computed: {
    currentLocale () {
        return this.$i18n.locale
    },
    stepListArray () {
       const tcbStepList = [
        { value: 0, text_en: 'Your application has been submitted, it is now in process.', text_bn: 'আপনার আবেদনটি দাখিল হয়েছে, এখন প্রক্রিয়াধীন অবস্থায় আছে।' },
        { value: 1, text_en: 'The Document verification of your application is in progress.', text_bn: 'আপনার আবেদনটি যাচাই বাছাই  কার্যক্রম চলমান আছে।' },
        { value: 2, text_en: 'The application is in Inspection under D.C. office.', text_bn: 'আপনার আবেদনটি ডি.সি. অফিসে সরজমিনে পরিদর্শন অবস্থায় আছে' },
        { value: 3, text_en: 'Your application is pending approval', text_bn: 'আপনার আবেদনটি অনুমোদনের জন্য অপেক্ষমান অবস্থায় আছে' },
        { value: 4, text_en: 'Your Application is Processing for Agreement', text_bn: 'আপনার আবেদনটি চুক্তির জন্য প্রক্রিয়াধীন' },
        { value: 5, text_en: 'Welcome you are now authorized dealer of TCB', text_bn: 'স্বাগতম আপনি এখন টিসিবির অনুমোদিত ডিলার' }
      ]
      return tcbStepList
    }
  },
  methods: {
    stepList (stepId) {
      const tcbStepList = this.stepListArray.find(item => item.value === stepId)
      return tcbStepList
    },
    getDateFormat (date) {
       dateFormat(date)
    },
    checkRenewStatus () {
      const expiredDate = this.appDetail.expire_date
      const currentDate = moment().format('YYYY-MM-DD')
      const expireNextDate = moment(expiredDate).add(1, 'day').format('YYYY-MM-DD')
      const expirePrevMonthDate = moment(expireNextDate).subtract(1, 'month').format('YYYY-MM-DD')
      const expireNextYearDate = moment(expiredDate).add(365, 'day').format('YYYY-MM-DD')
      const isBetween = moment(currentDate).isBetween(expirePrevMonthDate, expireNextYearDate, undefined, '[]')
      if (moment(currentDate).isAfter(expireNextYearDate)) {
        this.isExpiredStatus = true
      } else {
        this.isExpiredStatus = false
      }
      if (isBetween && this.appDetail.agreement_no) {
        this.isRenewButton = true
      } else {
        this.isRenewButton = false
      }
    },
    enableRenew () {
      this.$emit('enableRenew')
    },

    // for next implementation

    // checkPayment (appDetail) {
    //     if (appDetail.pay_status === 0 && appDetail.status === 4 && appDetail.approval_status === 1) {
    //        this.$refs['payment-modal'].show()
    //     } else {
    //       this.checkLimitApp(appDetail)
    //     }
    // },

    async checkLimitApp (appDetail) {
      if (appDetail.notice_id) {
        const success = await this.getNoticeActive(appDetail.notice_id)
        if (success && appDetail.type === 1) {
          const wardOrUnionId = appDetail.details?.company_union_id > 1 ? appDetail.details?.company_union_id : appDetail.details?.company_ward_id
            const type = appDetail.details?.company_union_id > 1 ? 1 : 2
            const key = appDetail.details?.company_union_id > 1 ? 'union_id' : 'ward_id'
            const dealerItem = this.recruitmentDelears.find(item => item[key] === wardOrUnionId)
            const dealers = dealerItem ? dealerItem.num_of_dealer : 0
            const params = {
              type: type,
              notice_id: appDetail.notice_id,
              [key]: wardOrUnionId
              // app_id: this.appDetail?.tcb_application_id > 0 ? this.appDetail.tcb_application_id : 0
            }
          this.appLimit = false
          const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, getWardUnionAppLimit, params)
          if (result.success) {
            if (dealers > 0 && dealers <= result.num_of_app) {
              this.appLimit = true
              this.$bvModal.hide('payment')
              this.$toast.warn({
                    title: this.$i18n.locale === 'en' ? 'Limit Exceeded..!' : 'কোটা শেষ ..!',
                    message: this.$i18n.locale === 'en' ? 'Sorry application quota is over, wait for next circular.' : 'দুঃখিত আবেদনের কোটা শেষ, পূনরায় সার্কুলারের জন্য অপেক্ষা করুন।',
                    color: '#F5A623',
                    timeOut: 15000

              })
            } else {
              // v-b-modal.payment
              this.$refs['payment-modal'].show()
              this.appLimit = false
            }
          } else {
            this.isLoad = true
          }
        }
      } else {
        this.$refs['payment-modal'].show()
      }
    },
    async getNoticeActive (noticeId) {
      const params = {
        id: noticeId
      }
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, noticeRecuirmentNoticeById, params)
      if (result.success) {
        this.recruitmentDelears = result.data.recruitment_delears
        return true
      } else {
         this.recruitmentDelears = []
         return false
      }
    }
  }
}
</script>
